<template>
  <div class="position-relative">
    <MomoNav>
      <div class="nav-item">
        <router-link :to="{ name: 'LiffHuggiesMomoRedeem' }">
          <img class="" src="./images/momo_button_redeem.png" />
        </router-link>
      </div>
      <div class="nav-item">
        <router-link :to="{ name: 'LiffHuggiesMomoRedeemRecord' }">
          <img class="" src="./images/momo_button_redeem_record.png" />
        </router-link>
      </div>
      <div class="nav-item">
        <a href="#">
          <img class="" src="./images/momo_button_point_record_active.png" />
        </a>
      </div>
    </MomoNav>
    <div>
      <div>
        <router-link :to="{ name: 'LiffHuggiesMomoMemberIndex' }">
          <img class="home" src="./images/momo_profile_back_btn.png" />
        </router-link>

        <img
          class="layoutMomo-title"
          src="./images/momo_title_point_record.png"
        />
      </div>
      <div class="records mt-3">
        <div class="head d-flex">
          <div class="date">日期</div>
          <div class="note">說明</div>
          <div class="point">點數</div>
        </div>
        <div class="">
          <div class="record" v-for="pointLog in pointLogs" :key="pointLog.id">
            <div class="date">{{ pointLog.date }}</div>
            <div class="note">{{ pointLog.title }}</div>
            <div class="point">{{ pointLog.point }}</div>
          </div>
          <div
            v-show="!noMore"
            class="button-loadmore text-center mt-3"
            @click="!noMore && loadMore()"
          >
            <img src="./images/momo_button_load_more.png" />
          </div>
        </div>
      </div>
      <div class="records-tip">
        點數依MOMO購物訂單實際消費金額給點<br />
        (扣除折價券或MO幣等折價後金額)
      </div>
    </div>
  </div>
</template>

<script>
import MomoNav from "./MomoNav";
import Huggies from "@/apis/liff/Huggies";
import withFetcher from "@/mixins/liff/withFetcher";
import moment from "moment";

const POINT_LOG_TYPE_EARN_POINTS = 1;
const POINT_LOG_TYPE_REDEEM_GIFT = 2;

const pointDisplayStrategies = {
  [POINT_LOG_TYPE_EARN_POINTS]: (pl) => ((pl.point > 0) ? "+" : "") + `${pl.point}`, // eslint-disable-line no-unused-vars
  [POINT_LOG_TYPE_REDEEM_GIFT]: (pl) => `${pl.point}`, // eslint-disable-line no-unused-vars
};

export default {
  mixins: [withFetcher],
  components: {
    MomoNav,
  },
  data() {
    return {
      pointLogs: [],
      page: 1,
      noMore: false,
    };
  },
  methods: {
    async fetch() {
      await this.fetchPointLogs();
    },
    loadMore() {
      this.page = this.page + 1;
      this.fetchPointLogs({ page: this.page });
    },
    async fetchPointLogs() {
      let res = await Huggies.getInstance().listPointsLogs({ page: this.page });
      let point_logs = res.data;
      if (!point_logs || !point_logs.length) {
        this.pointLogs = [];
        return;
      }

      let rows = point_logs
        .map((pl) => ({
          id: pl.id,
          title:
            pl.type === POINT_LOG_TYPE_EARN_POINTS
              ? ((pl.batch_total_points >= 0) ? "Momo 消費" : "Momo 退貨")
              : `兌換 ${pl.gift?.name}`,
          point: pl.batch_total_points,
          date: moment(pl.created_at).format("YYYY/MM/DD"),
          type: pl.type,
          status: "finish",
        }))
        .map(function (pl) {
          pl.point = pointDisplayStrategies[pl.type](pl);
          return pl;
        });

      if (res.links.next === null) {
        this.noMore = true;
      }
      this.pointLogs = this.pointLogs.concat(rows);
    },
  },
};
</script>

<style lang="scss">
.records {
  margin-bottom: 30px;
  background: #fff;
  padding: 0 10px 10px 10px;
  border-radius: 15px;
  border: 2px solid #f5cf7a;
  color: #2a4221;

  .record {
    display: flex;
    border-bottom: 1px solid #2a4221;
  }

  .head {
    border-bottom: 1px solid #2a4221;
    font-size: 20px;
    font-weight: 500;
  }

  .date,
  .note,
  .point {
    padding: 8px 5px;
  }

  .date {
    width: 30%;
  }

  .note {
    width: 50%;
  }

  .point {
    width: 20%;
  }

  .button-loadmore {
    img {
      width: 35%;
    }
  }
}

.records-tip {
  padding: 5px;
  margin-bottom: 3px;
  text-align: center;
  color: #2a4221;
  font-size: 10px;
}
</style>
